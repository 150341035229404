'use strict';

window.$ = window.jQuery = $;

import './homepage-reviews.js';

(function ($) {

  $.each($('.thumbnailContainer'), function (index, value) {
    if (index >= 6 && window.innerWidth <= 720) {
      $(this).remove();
    }
  })

})($);